import React from 'react';
import ReactDOM from 'react-dom';
import { StateProvider } from './hooks/useStateValue';
import { rootReducer as reducer, initialState } from './reducers';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Router from './containers/App';

ReactDOM.render(
    <React.StrictMode>
        <StateProvider reducer={reducer} initialState={initialState}>
            <Router />
        </StateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
