import authReducer, { initialAuthState } from './authReducer';
import gameStateReducer, { initialGameState } from './gameStateReducer';
import activePlayerReducer, { initialActivePlayerState } from './activePlayerReducer'
import playerGifsReducer, { initialPlayerGifsState } from './playerGifsReducer';
import votingReducer, { initialVotingState } from './votingReducer';

export const rootReducer = (state, action) => ({
    auth: authReducer(state.auth, action),
    game_state: gameStateReducer(state.game_state, action),
    active_player: activePlayerReducer(state.active_player, action),
    player_gifs: playerGifsReducer(state.player_gifs, action),
    voting: votingReducer(state.voting, action)
});

export const initialState = {
    auth: initialAuthState,
    game_state: initialGameState,
    active_player: initialActivePlayerState,
    player_gifs: initialPlayerGifsState,
    voting: initialVotingState
};