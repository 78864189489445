import {
    UPDATE_ACTIVE_PLAYER
} from '../actions';

export const initialActivePlayerState = {
    uid: null,
    hint: null,
    chosenGif: null
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_ACTIVE_PLAYER:
            return { ...state, ...payload }
        default:
            return state;
    }
};

export default reducer;