import React from 'react';
import { Fade, Zoom } from 'react-reveal';

import { useStateValue } from '../../hooks/useStateValue';
import { AuthenticatedUserButtonGroup, SignInButton } from '../../components/Buttons'

import './styles.css';

function SignIn({ navigate }) {

    const [{ auth }] = useStateValue();

    return (
        <div className="App">
            <header className="App-header">
                <div className="logo">
                    <Fade left>
                        Riff
                    </Fade>
                    <span className="gameSpan">
                        <Fade right>
                            the GIF!
                        </Fade>
                    </span>
                </div>
                {auth.user ? <AuthenticatedUserButtonGroup navigate={navigate} /> : <SignInButton />}
            </header>
        </div>
    );
}

export default SignIn;
