import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useStateValue } from '../../hooks/useStateValue';
import { updateUserAuth } from '../../actions';

// HOCs
import ProtectedRoute from '../../hocs/withProtectedRoute';
import Listener from '../../hocs/withFirebaseListener';

// Screens
import SignIn from '../SignIn';
import CreateGame from '../CreateGame';
import JoinGame from '../JoinGame';
import WaitingRoom from '../WaitingRoom';
import GifGrid from '../../components/GifGrid';
import Stage1 from '../Stage1'; // Active player chooses, others wait
import Stage2 from '../Stage2'; // Active waits, others choose
import Stage3 from '../Stage3'; // Voting happens
import Stage4 from '../Stage4'; // results

function App() {
    const [{ auth }, dispatch] = useStateValue();

    // Load auth state at application start up
    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            dispatch(updateUserAuth(user));
        });
    }, []);

    // Wait for authentication to be loaded
    if (!auth.init) return null;

    return (
        <Router>
            <SignIn path="/" default />
            <ProtectedRoute path="/create" component={CreateGame} />
            <ProtectedRoute path="/join" component={JoinGame} />
            <ProtectedRoute path="/game/:gameId" component={Listener}>
                <WaitingRoom path="waiting-room" />
                <Stage1 path="/stage/1" />
                <Stage2 path="/stage/2" />
                <Stage3 path="/stage/3" />
                <Stage4 path="/stage/4" />
            </ProtectedRoute>
            <GifGrid path="grid" />
        </Router>
    );
};

export default App;