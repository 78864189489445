import React from 'react';
import map from 'lodash.map';
import { Redirect } from '@reach/router';

import { useStateValue } from '../../hooks/useStateValue';
import { StartGameButton } from '../../components/Buttons';
import { STAGE_1 } from '../../lib/constants';
import AwesomeButton from 'react-awesome-button/src/components/AwesomeButton';

import './styles.css';

function WaitingRoomPlayersTable({ players }) {
    let index = 0, cellBg
    const rows = map(players, (player, uid) => {
        index++
        cellBg = index % 2 != 0 ? 'dark' : 'light'
        return (
            <tr key={uid} className={cellBg}>
                <td>
                    <img alt="profile" className="App-profile-image" src={player.photoURL} />
                </td>
                <td>{player.displayName}{player.isGameAdmin && " (Admin)"}</td>
            </tr>
        );
    });

    return (
        <table className="table">
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

function WaitingRoom({ gameId, navigate }) {

    const [{ game_state, auth }] = useStateValue();

    if (!game_state.players) return null;

    if (game_state.stage === STAGE_1) {
        return (
            <Redirect
                to={`/game/${ game_state.game_id }/stage/1`}
                noThrow
            />
        );
    }

    return (
        <div className="container App App-header">
                <h1 className='header' style={{paddingBottom: '20px'}}>{`Waiting Room for ${gameId }`}</h1>
                <div className="gradient-border" id="box">
                <h2>Players in Room:</h2>
                <WaitingRoomPlayersTable players={game_state.players} />
                <div className="App-button-group create-button-group">
                    {game_state.players[auth.user.uid].isGameAdmin &&
                        <StartGameButton
                            game_state={game_state}
                        />
                    }
                    <AwesomeButton type="secondary" onPress={() => navigate('/')}>Go Back</AwesomeButton>
                </div>
            </div>
        </div>
    );
}

export default WaitingRoom;