export const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH';
export const UPDATE_GAME_STATE = 'UPDATE_GAME_STATE';
export const UPDATE_ACTIVE_PLAYER = 'UPDATE_ACTIVE_PLAYER';
export const UPDATE_PLAYER_GIFS = 'UPDATE_PLAYER_GIFS';
export const CLEAR_PLAYER_GIFS = 'CLEAR_PLAYER_GIFS';
export const UPDATE_VOTING = 'UPDATE_VOTING';
export const CLEAR_VOTING = 'CLEAR_VOTING';

export const updateUserAuth = (user) => {
    if (user) {
        const { displayName, email, photoURL, uid } = user
        return {
            type: UPDATE_USER_AUTH,
            payload: {
                user: { displayName, email, photoURL, uid }
            }
        };
    }
    // not logged in, so user is null.
    return {
        type: UPDATE_USER_AUTH,
        payload: {
            user
        }
    };
};

export const updateGameStateId = (gameId) => {
    return {
        type: UPDATE_GAME_STATE,
        payload: {
            game_id: gameId
        }
    };
};

export const updateStatePlayers = (players) => {
    return {
        type: UPDATE_GAME_STATE,
        payload: {
            players
        }
    };
};

export const updateGameState = (data) => {
    return {
        type: UPDATE_GAME_STATE,
        payload: data
    };
};

export const updateActivePlayer = (data) => {
    return {
        type: UPDATE_ACTIVE_PLAYER,
        payload: data
    };
}

export const updatePlayerGifs = (data) => {
    if (data) {
        return {
            type: UPDATE_PLAYER_GIFS,
            payload: data
        };
    }
    return {
        type: CLEAR_PLAYER_GIFS
    }
};

export const updateVoting = (data) => {
    if (data) {
        return {
            type: UPDATE_VOTING,
            payload: data
        };
    }
    return {
        type: CLEAR_VOTING
    }
};
