import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClipboardCopyIcon from '@material-ui/icons/FileCopy';
import { getNewId } from '../../services/idService';
import { createNewGame } from '../../services/firebaseRealTimeDatabase';
import { useStateValue } from '../../hooks/useStateValue';
import AwesomeButton from 'react-awesome-button/src/components/AwesomeButton';

// TODO: This will create a game with the starter pack of GIFs.

function CreateGame({ navigate }) {
    const [id, updateId] = useState(getNewId())
    const [copyToClipboard, setCopyToClipboard] = useState(false);
    const [{ auth }] = useStateValue();

    const data = {
        gameId: id,
        user: auth.user
    };

    return (
        <div className="App App-header" id="create-game">
            <h1>Create New Game</h1>
            <div className="gradient-border" id="box">
                <p className="helperText">Your room ID is...</p>
                <h2>{id}</h2>
                <CopyToClipboard className="copy-to-clipboard" text={{id}} onCopy={() =>{ setCopyToClipboard(true) }}>
                    <span className="clipboard-copy-icon"><ClipboardCopyIcon /></span>
                </CopyToClipboard>
                {copyToClipboard ? <span>Copied!</span> : null}
                <p className="helperText">
                    Share this ID with your friends so they can join your game!
                </p>
                <div className="App-button-group create-button-group">
                    <AwesomeButton
                        onPress={async () => {
                            await createNewGame(data);
                            navigate(`/game/${ id }/waiting-room`);
                        }}
                    >
                        Create Room
                    </AwesomeButton>
                    <AwesomeButton type="secondary"  onPress={() => updateId(getNewId())}>Generate New ID</AwesomeButton>
                </div>
            </div>
        </div>
    );
};

export default CreateGame;