import React from 'react';
import { Redirect } from '@reach/router';
import { useStateValue } from '../hooks/useStateValue';

function ProtectedRoute({ component: Component, children, ...rest }) {
    const [state] = useStateValue();

    if (state.auth.user) {
        return (
            <Component {...rest}>
                {children}
            </Component>
        );
    }

    return <Redirect to="/" noThrow />

};

export default ProtectedRoute;
