import firebase from 'firebase/app';
import 'firebase/database';
import { firebaseConfig } from '../config/firebaseConfig';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const database = firebase.database();

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_FIREBASE_USE_EMULATOR) {
    const emulatorHost = process.env.REACT_APP_EMULATOR_HOST || 'localhost';
    database.useEmulator(emulatorHost, 9000);
}