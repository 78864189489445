import {
    UPDATE_PLAYER_GIFS,
    CLEAR_PLAYER_GIFS
} from '../actions';

export const initialPlayerGifsState = null;

const reducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_PLAYER_GIFS:
            return { ...state, ...payload }
        case CLEAR_PLAYER_GIFS:
            return initialPlayerGifsState;
        default:
            return state;
    }
};

export default reducer;