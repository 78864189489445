import { useState, useEffect } from 'react';
import { Grid } from '@giphy/react-components';
import { getGifsByIds } from '../../services/giphyService';

function GifGrid({ gifIds, handleGifSelect }) {
    const [gifs, updateGifs] = useState([]);
    const [selectedDomNode, updateSelectedDomNode] = useState(null);

    useEffect(() => {
        async function fetchGifs() {
            const result = await getGifsByIds(gifIds);
            updateGifs(result);
        }
        fetchGifs();
    }, []);

    if (!gifs.length) return null;

    return (
        <Grid
            initialGifs={gifs}
            columns={3}
            gutter={6}
            width={window.screen.availWidth / 2}
            noLink
            hideAttribution
            onGifClick={(g, e) => {
                // remove previously selected
                if (selectedDomNode) {
                    selectedDomNode.target.classList.remove('App-selected-gif');
                }
                e.target.classList.add('App-selected-gif');
                updateSelectedDomNode(e);
                if (handleGifSelect) {
                    handleGifSelect(g.id);
                }
            }}
            className="App-gif"
        />
    );
}

export default GifGrid;