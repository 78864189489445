import React, { useState } from 'react';
import { joinGame } from '../../services/firebaseRealTimeDatabase';
import { useStateValue } from '../../hooks/useStateValue';

function JoinGame({ navigate }) {
    const [gameId, updateGameId] = useState('');
    const [{ auth }] = useStateValue();

    const joinGameData = {
        gameId,
        user: auth.user
    };

    return (
        <div className="App App-header">
            <h1>Join Game</h1>
            <input
                type="text"
                value={gameId}
                onChange={(e) => updateGameId(e.target.value)}
            />
            <div className="App-button-group">
                <button onClick={async () => {
                    // TODO: Check if room exists. If it doesn't display error.
                    await joinGame(joinGameData);
                    navigate(`/game/${ gameId }/waiting-room`);
                }}
                >Join</button>
                <button onClick={() => navigate('/')}>Go Back</button>
            </div>
        </div>
    );
}

export default JoinGame;