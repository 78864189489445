import React, { createContext, useContext, useReducer } from 'react';

export const StateContext = createContext();

// Higher Order component that makes global state available to all components
export const StateProvider = ({ reducer, initialState, children }) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

// Custom Hook to get state and dispatch function
export const useStateValue = () => useContext(StateContext);