import React, { useState } from 'react';
import { Redirect } from '@reach/router';

import GifGrid from '../../components/GifGrid';
import { useStateValue } from '../../hooks/useStateValue';
import { shareHintAndGif } from '../../services/firebaseRealTimeDatabase';
import { STAGE_2 } from '../../lib/constants';

import AwesomeButton from 'react-awesome-button/src/components/AwesomeButton';

import './style.css'

// Active Player Chooses, Others Wait

function ActivePlayerChooses({ gifIds, gameId, uid }) {
    const [chosenGif, updateChosenGif] = useState(null);
    const [hint, updateHint] = useState('');

    return (
        <div className="container App App-header">
            <h1 style={{ fontWeight: 'bold' }}>You are the active player</h1>
            <h2>Select a GIF and write a hint!</h2>
            <GifGrid
                gifIds={gifIds}
                handleGifSelect={(gifId) => {
                    updateChosenGif(gifId);
                }}
            />
            <div>
                <div style={{ display: "inline-block" }}>
                    <input
                        type="text"
                        placeholder="type your hint here"
                        value={hint}
                        onChange={(e) => updateHint(e.target.value)}
                        style={{ marginRight: '20px', height: '44px' }}
                    />
                </div>
                <div style={{ display: "inline-block" }}>
                    <AwesomeButton
                        onPress={async () => {
                            await shareHintAndGif({
                                hint,
                                chosenGif,
                                gameId,
                                gifIds,
                                uid
                            });
                        }}
                        disabled={!chosenGif || !hint}
                    >
                        Send hint!
                    </AwesomeButton>
                </div>
            </div>
        </div>
    );
}

function OtherPlayerWaits({ gifIds }) {
    return (
        <>
            <h1>Wait for a hint!</h1>
            <h2>Meanwhile, have a look at your gifs!</h2>
            <GifGrid gifIds={gifIds} />
        </>
    );
}

function Stage1({ gameId }) {
    const [{ auth, active_player, game_state }] = useStateValue();

    if (game_state.stage === STAGE_2) {
        return <Redirect
            to={`/game/${ game_state.game_id }/stage/2`}
            noThrow
        />
    }

    if (!game_state.players) return null;

    const gifIds = game_state.players[auth.user.uid].gifs;

    if (auth.user.uid === active_player.uid) {
        return (
            <ActivePlayerChooses
                gifIds={gifIds}
                gameId={gameId}
                uid={auth.user.uid}
            />
        );
    }

    return (
        <OtherPlayerWaits gifIds={gifIds} />
    );
}

export default Stage1;