import firebase from 'firebase/app';
import 'firebase/auth';

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_FIREBASE_USE_EMULATOR) {
    const emulatorHost = process.env.REACT_APP_EMULATOR_HOST || 'localhost';
    firebase.auth().useEmulator(`http://${ emulatorHost }:9099`);
}

export const handleGoogleSignIn = async () => {
    const result = await firebase.auth().signInWithPopup(googleAuthProvider);
    const { credential: { accessToken }, user } = result;
    return { accessToken, user };
};

export const handleGoogleSignOut = async () => {
    await firebase.auth().signOut();
};

export const getCurrentGoogleUser = () => {
    return firebase.auth().currentUser;
};