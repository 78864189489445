import React, { useState } from 'react';
import { useNavigate, Redirect } from '@reach/router';
import { vote, calculateScores } from '../../services/firebaseRealTimeDatabase';
import GifGrid from '../../components/GifGrid';
import SingleGif from '../../components/SingleGif';
import { usePlayerGifs, useCalculateScores } from '../../hooks';
import { useStateValue } from '../../hooks/useStateValue';

const STAGE_4 = 4;
const STAGE_5 = 5;

// Active Player Chooses, Others Wait

function ActivePlayerWaits({ gameId, numPlayers, numVotes, hint, chosenGif, remainingGifs }) {
    const [points] = useCalculateScores();
    const [{ game_state }] = useStateValue();

    const resultsReady = numPlayers === numVotes + 1;
    const message = resultsReady ? 'Oh snap! Results are in!' : `Your hint was ${ hint }`

    return (
        <>
            <h1>Others are voting!</h1>
            <h2>{message}</h2>
            {!resultsReady && (
                <>
                    <SingleGif gifId={chosenGif} />
                    <h2>Your other gifs</h2>
                    <GifGrid gifIds={remainingGifs} />
                </>
            )}
            {resultsReady &&
                <button onClick={async () => await calculateScores(gameId, points, game_state.players)}>
                    Show Results!
                </button>}
        </>
    );
}

function OtherPlayerVotes({ gameId, uid, player_gifs }) {
    // Example custom hook
    const { data, isLoading } = usePlayerGifs();
    const [chosenGif, updateChosenGif] = useState(null);
    const [voted, updateVoted] = useState(false);

    const message = voted ? 'Thanks! Wait while others vote' : 'Vote for active player\'s GIF';

    return (
        <>
            <h1>{message}</h1>
            {!isLoading &&
                <GifGrid
                    gifIds={data}
                    handleGifSelect={(gif) => updateChosenGif(gif)}
                />
            }
            <button
                onClick={() => {
                    vote(gameId, chosenGif, uid, player_gifs);
                    updateVoted(true);
                }}
                disabled={voted}
            >
                Vote!
            </button>
        </>
    );
}

function Stage3({ gameId }) {
    const [{ auth, active_player, game_state, voting, player_gifs }] = useStateValue();

    // TODO: do better than this
    if (!game_state.players) return null;

    if (game_state.stage === STAGE_4 || game_state.stage === STAGE_5) {
        return <Redirect to={`/game/${ gameId }/stage/4`} noThrow />
    }

    if (auth.user.uid === active_player.uid) {
        const numPlayers = Object.keys(game_state.players).length;
        const numVotes = voting ? Object.keys(voting).length : 0;
        return (
            <ActivePlayerWaits
                gameId={game_state.game_id}
                hint={active_player.hint}
                numPlayers={numPlayers}
                numVotes={numVotes}
                chosenGif={active_player.chosenGif}
                remainingGifs={game_state.players[auth.user.uid].gifs}
            />
        );
    }

    return (
        <OtherPlayerVotes
            gameId={game_state.game_id}
            uid={auth.user.uid}
            player_gifs={player_gifs}
        />
    );
}

export default Stage3;