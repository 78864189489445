import React, { useState } from 'react';
import GifGrid from '../../components/GifGrid';
import SingleGif from '../../components/SingleGif';
import { useStateValue } from '../../hooks/useStateValue';
import { chooseGif } from '../../services/firebaseRealTimeDatabase';
import { Redirect } from '@reach/router';
import { Gif } from '@giphy/react-components';

const STAGE_3 = 3;

// Active Player Chooses, Others Wait

function ActivePlayerWaits({ hint, chosenGif, remainingGifs }) {
    return (
        <>
            <h1>{`Your hint was ${ hint }`}</h1>
            <h2>This was your chosen gif:</h2>
            <SingleGif gifId={chosenGif} />
            <h2>Your other GIFs</h2>
            <GifGrid gifIds={remainingGifs} />
        </>
    );
}

function OtherPlayerChooses({ hint, uid, game_state, gifIds, gameId }) {
    const [chosenGif, updateChosenGif] = useState(null);
    const [submitted, updateSubmitted] = useState(false);
    const playerLength = Object.keys(game_state.players).length;

    const message = submitted ? 'Thanks! Wait for the others to choose.' : 'Choose a GIF that represents this hint.';

    return (
        <>
            <h1>{`The hint is: ${ hint }`}</h1>
            <h2>{message}</h2>
            <GifGrid
                gifIds={gifIds}
                handleGifSelect={(gif) => {
                    updateChosenGif(gif);
                }}
            />
            <button
                onClick={() => {
                    chooseGif(gameId, chosenGif, uid, gifIds, playerLength);
                    updateSubmitted(true);
                }}
                disabled={submitted}
            >
                Select Gif
            </button>
        </>
    );
}

function Stage2({ gameId }) {
    const [{ auth, active_player, game_state }] = useStateValue();

    // TODO: do better than this
    if (!game_state.players) return null;

    if (game_state.stage === STAGE_3) {
        return <Redirect to={`/game/${ gameId }/stage/3`} noThrow />
    }

    const gifIds = game_state.players[auth.user.uid].gifs;

    if (auth.user.uid === active_player.uid) {
        return (
            <ActivePlayerWaits
                hint={active_player.hint}
                chosenGif={active_player.chosenGif}
                remainingGifs={gifIds} // the active players remaining 5 gifs
            />
        );
    }

    return (
        <OtherPlayerChooses
            hint={active_player.hint}
            uid={auth.user.uid}
            game_state={game_state}
            gifIds={gifIds}
            gameId={gameId}
        />
    );
}

export default Stage2;