import React, { useEffect } from 'react';
import { useStateValue } from '../hooks/useStateValue';
import {
    gameByIdRef,
    activePlayerByGameIdRef,
    playerGifsByGameIdRef,
    votingByGameIdRef
} from '../services/firebaseRealTimeDatabase';
import {
    updateGameStateId,
    updateGameState,
    updateActivePlayer,
    updatePlayerGifs,
    updateVoting
} from '../actions';

function Listener({ gameId, children }) {
    const [state, dispatch] = useStateValue();
    useEffect(() => {
        dispatch(updateGameStateId(gameId));

        // Listen to members joining the game room and for game_state stage
        gameByIdRef(gameId).on('value', (snapshot) => {
            const { players, stage } = snapshot.val();
            dispatch(updateGameState({ players, stage }));
        });

        // Listen to changes in the active_player
        activePlayerByGameIdRef(gameId).on('value', (snapshot) => {
            const active_player = snapshot.val();
            if (active_player) {
                dispatch(updateActivePlayer(active_player));
            }
        });

        // Listen to players selecting gifs based on hint
        playerGifsByGameIdRef(gameId).on('value', (snapshot) => {
            const player_gifs = snapshot.val();
            dispatch(updatePlayerGifs(player_gifs));
        });

        // Listen to players voting
        votingByGameIdRef(gameId).on('value', (snapshot) => {
            const voting = snapshot.val();
            dispatch(updateVoting(voting));
        });

        return function detachFirebaseListeners() {
            // TODO: Remove player from waiting room if the navigate away
            gameByIdRef(gameId).off();
            activePlayerByGameIdRef(gameId).off();
            playerGifsByGameIdRef(gameId).off();
            votingByGameIdRef(gameId).off();
            console.log('Detached!')
        }
    }, []);

    // TODO: Put in a nice loader here, and make this work
    // Make all initial reducers null
    if (!state.auth.user || !state.game_state) return null;

    return children;
};

export default Listener;