import {
    UPDATE_VOTING,
    CLEAR_VOTING
} from '../actions';

export const initialVotingState = null;

const reducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_VOTING:
            return { ...state, ...payload }
        case CLEAR_VOTING:
            return initialVotingState;
        default:
            return state;
    }
};

export default reducer;