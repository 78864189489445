import React, { useState, useEffect } from 'react';
import { Gif } from '@giphy/react-components';
import { getSingleGif, getGifById } from '../../services/giphyService';

function SingleGif({ tag, gifId }) {

    const [gif, updateGif] = useState(null);

    // Get a gif
    useEffect(() => {
        async function fetchData() {
            const data = gifId ? await getGifById(gifId) : await getSingleGif(tag);
            updateGif(data);
        }
        fetchData();
    }, []);

    if (gif) {
        return (
            <Gif gif={gif} width={300} noLink hideAttribution />
        );
    }

    return null
}

export default SingleGif;