import { UPDATE_USER_AUTH } from '../actions';

export const initialAuthState = {
    user: null,
    init: false
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_USER_AUTH:
            return { user: payload.user, init: true }
        default:
            return state;
    }
};

export default reducer;