import reduce from 'lodash.reduce';

export const calculateScores = (player_gifs, voting) => {
    let numPlayers = 0;
    let activePlayerVoteCount = 0;
    const possibleBonuses = reduce(voting, (acc, vote, playerUid) => {
        // increment player
        numPlayers++;

        // increment if it's activePlayer
        if (vote.isOwnerActive) {
            activePlayerVoteCount++;
        } else {
            // otherwise keep track of player for possible bonuses
            if (acc[vote.ownerUid]) {
                acc[vote.ownerUid]++;
            } else {
                acc[vote.ownerUid] = 1;
            }
        }
        return acc;
    }, {});

    const result = reduce(player_gifs, (acc, player, uid) => {
        // If everyone voted for the active player, they get two points. Ignore the bonuses.
        // Active player gets nothing.
        if (numPlayers === activePlayerVoteCount) {
            acc[uid] = player.isActive ? 0 : 2;
            return acc;
        }

        // If nobody voted for active player, everyone gets two points plus bonuses.
        if (activePlayerVoteCount === 0) {
            acc[uid] = player.isActive ? 0 : 2;
            acc[uid] += possibleBonuses[uid] ?? 0;
            return acc;
        }

        // If you voted for the active player or if you are the active player, get 3 points.
        // And apply bonuses if applicable
        if (voting[uid]?.isOwnerActive || player.isActive) {
            acc[uid] = 3;
            acc[uid] += possibleBonuses[uid] ?? 0;
            return acc;
        }

        // The above conditions don't apply. Check for bonuses.
        acc[uid] = possibleBonuses[uid] ?? 0;
        return acc;

    }, {});

    return result;
};