import reduce from 'lodash.reduce';
import shuffle from 'lodash.shuffle';
import { useState, useEffect } from 'react';
import { useStateValue } from './useStateValue';
import { calculateScores } from '../lib/helpers';
import { playerGifsByGameIdRef } from '../services/firebaseRealTimeDatabase';
import { updatePlayerGifs } from '../actions';

export const usePlayerGifs = () => {
    const [data, updateData] = useState(null);
    const [isLoading, updateIsLoading] = useState(true);
    const [{ game_state, auth }, dispatch] = useStateValue();

    useEffect(() => {
        const getPlayerGifs = async () => {
            const snapshot = await playerGifsByGameIdRef(game_state.game_id).once('value');
            const playerGifs = snapshot.val();

            dispatch(updatePlayerGifs(playerGifs));

            // filter out player's own gif so they don't vote on it
            const gifIds = reduce(playerGifs, (acc, gif, uid) => {
                if (uid !== auth.user.uid) {
                    acc.push(gif.chosenGif);
                }
                return acc;
            }, []);

            updateData(shuffle(gifIds));
            updateIsLoading(false);
        }
        getPlayerGifs();
    }, []);

    return { data, isLoading };
};

export const useCalculateScores = () => {
    const [{ player_gifs, voting }] = useStateValue();
    const results = calculateScores(player_gifs, voting);
    return [results];
};
