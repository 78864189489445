import {
    UPDATE_GAME_STATE
} from '../actions';

export const initialGameState = {
    game_id: null,
    players: null,
    stage: 0
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_GAME_STATE:
            return { ...state, ...payload }
        default:
            return state;
    }
};

export default reducer;