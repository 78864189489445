import map from 'lodash.map';
import reduce from 'lodash.reduce';
import { Redirect } from '@reach/router';
import { useStateValue } from '../../hooks/useStateValue';
import { useCalculateScores } from '../../hooks';
import SingleGif from '../../components/SingleGif';
import { NextRoundButton } from '../../components/Buttons';
import { STAGE_4 } from '../../lib/constants';

const STAGE_1 = 1;
const STAGE_5 = 5;

function RenderProfilePics({ matchedUid }) {
    const [{ voting, game_state }] = useStateValue();

    const profilePics = reduce(voting, (acc, vote, uid) => {
        if (vote.ownerUid === matchedUid) {
            acc.push(
                <div>
                    <img
                        key={uid}
                        src={game_state.players[uid].photoURL}
                        className="App-profile-image"
                    />
                </div>
            );
        }
        return acc;
    }, []);

    return (
        <div>
            {profilePics.length ? profilePics : 'Much sad, no votes :-('}
        </div>
    );
}

function ResultsTable({ players, points }) {
    const rows = map(players, (player, uid) => {
        return (
            <tr key={uid}>
                <td>{player.displayName}</td>
                <td>{points[uid]}</td>
                <td>{player.points}</td>
            </tr>
        );
    });

    return (
        <table className="table table-dark table-striped">
            <thead>
                <tr>
                    <td>Player</td>
                    <td>Points</td>
                    <td>TotalPoints</td>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

function Winner({ players }) {
    let winningUid;
    let maxPoints = 0;
    for (const uid in players) {
        if (players[uid].points > maxPoints) {
            winningUid = uid;
            maxPoints = players[uid].points;
        }
    }
    return (
        <h1>{`${ players[winningUid].displayName } is the winner with ${ maxPoints } points`}</h1>
    );
}

function Stage4({ gameId }) {
    const [{ game_state, auth, active_player }] = useStateValue();

    const [points] = useCalculateScores();

    if (!active_player.uid) return null;

    if (game_state.stage === STAGE_1) {
        return <Redirect to={`/game/${ game_state.game_id }/stage/1`} noThrow />
    }

    return (
        <div className="container">
            {game_state.stage === STAGE_5 &&
                <Winner players={game_state.players} />
            }
            <h1>Results!</h1>
            <div className="row">
                <div className="col-md-6">
                    <h2>Active Player GIFs</h2>
                    <SingleGif gifId={active_player.chosenGif} />
                </div>
                <div className="col-md-6">
                    <h2>Players Who Chose Correctly</h2>
                    <RenderProfilePics matchedUid={active_player.uid} />
                </div>
            </div>
            <ResultsTable
                players={game_state.players}
                points={points}
            />
            {auth.user.uid === active_player.uid &&
                <NextRoundButton
                    active_player={active_player}
                    game_state={game_state}
                />
            }
        </div>
    );
}

export default Stage4;