import { useNavigate } from '@reach/router';
import GoogleButton from 'react-google-button'
import AwesomeButton from 'react-awesome-button/src/components/AwesomeButton';
import "react-awesome-button/dist/styles.css";
import { handleGoogleSignIn, handleGoogleSignOut } from '../../services/googleAuthService';
import { startGame, advanceNextRound } from '../../services/firebaseRealTimeDatabase';

// TODO: Apply bootstrap styles

export function SignInButton() {
    return (
        <GoogleButton onClick={() => handleGoogleSignIn()} />
    );
}

export function SignOutButton() {
    return (
        <AwesomeButton type="secondary" onPress={() => handleGoogleSignOut()}>
            Sign Out
        </AwesomeButton>
    );
}

export function CreateGameButton() {
    const navigate = useNavigate();
    return (
        <AwesomeButton type="link" onPress={() => navigate('/create')}>
            Create Game
        </AwesomeButton>
    );
}

export function JoinGameButtion() {
    const navigate = useNavigate();
    return (
        <AwesomeButton onPress={() => navigate('/join')}>Join Game</AwesomeButton>
    );
}

export function AuthenticatedUserButtonGroup() {
    return (
        <div className="App-button-group">
            <CreateGameButton />
            <JoinGameButtion />
            <SignOutButton />
        </div>
    );
}

export function StartGameButton({ game_state }) {
    return (
        <AwesomeButton onPress={() => startGame(game_state)}>
            Start Game!
        </AwesomeButton>
    );
}

export function NextRoundButton({ active_player, game_state }) {
    return (
        <AwesomeButton onPress={() => advanceNextRound(active_player, game_state)}>
            Next Round
        </AwesomeButton>
    );
}