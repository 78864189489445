import { GiphyFetch } from '@giphy/js-fetch-api';
// TODO: Get a new key and migrate these as cloud functions
const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY);

export const getSingleGif = async (tag = null) => {
    const { data } = await gf.random({ rating: 'pg', type: 'gifs', ...(tag) && { tag } });
    return data;
};

export const getTrendingGifs = async (limit = 50, offset = 0) => {
    const { data } = await gf.trending({ type: 'gifs', offset, limit });
    return data;
};

export const getGifsByIds = async (gifIds) => {
    const { data } = await gf.gifs(gifIds);
    return data;
};

export const getGifById = async (gifId) => {
    const { data } = await gf.gif(gifId);
    return data;
};
