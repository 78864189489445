import reduce from 'lodash.reduce';
import shuffle from 'lodash.shuffle';
import { database } from './firebase';
import { getTrendingGifs } from './giphyService';

import {
    STAGE_1,
    STAGE_2,
    STAGE_3,
    STAGE_4,
    STAGE_5,
    STARTER_GIF_PACK
} from '../lib/constants';

// Refs

export const playersByGameIdRef = (gameId) => {
    return database.ref(`/game_state/${ gameId }/players`);
};

export const playerByIdRef = (uid, gameId) => {
    return database.ref(`/game_state/${ gameId }/players/${ uid }`);
};

export const gameByIdRef = (gameId) => {
    return database.ref(`/game_state/${ gameId }`);
};

export const gifDeckByIdRef = (gameId) => {
    return database.ref(`/gif_deck/${ gameId }/gifs`);
};

export const activePlayerByGameIdRef = (gameId) => {
    return database.ref(`/active_player/${ gameId }`);
};

export const playerGifsByGameIdRef = (gameId) => {
    return database.ref(`/player_gifs/${ gameId }`);
};

export const votingByGameIdRef = (gameId) => {
    return database.ref(`/voting/${ gameId }`);
};

// Database Actions

export const createNewGame = async (data) => {
    // TODO: Check if game ID exists.

    const { gameId, user: { displayName, photoURL, uid } } = data;

    // const gifs = await getTrendingGifs();
    // const gifIds = gifs.map(gif => gif.id);

    // Git gif deck for game room and shuffle them
    const gifDeckSnapshot = await gifDeckByIdRef(STARTER_GIF_PACK).once('value');
    const gifIds = shuffle(gifDeckSnapshot.val());

    await Promise.all([
        database.ref(`/game_state/${ gameId }`).update({
            players: {
                [uid]: { displayName, photoURL, points: 0, nextPlayerUid: 0, isGameAdmin: true }
            },
            stage: 0
        }),
        database.ref(`/gif_deck/${ gameId }`).update({
            gifs: gifIds
        })
    ]);
};

export const joinGame = async (data) => {
    // TODO: make sure gameId exists. Currently if it doesn't, it will create a new room.
    // Update the rules in Firebase Console. Catch the error here and handle.
    const { gameId, user: { displayName, photoURL, uid } } = data;
    await database.ref(`/game_state/${ gameId }/players/${ uid }`).update(
        {
            displayName,
            photoURL,
            points: 0,
            nextPlayerUid: 0,
            isGameAdmin: false
        }
    );
};

export const startGame = async (game_state) => {
    const { game_id, players } = game_state;

    // Git gif deck for game room and shuffle them
    const gifDeckSnapshot = await gifDeckByIdRef(game_id).once('value');
    const gifIds = gifDeckSnapshot.val();

    // Give each player 6 gifs and a nextPlayerUid
    const playerIds = Object.keys(players);
    const lastPlayerIdx = playerIds.length - 1;
    const updated_players = reduce(playerIds, (acc, playerId, idx) => {
        const gifs = gifIds.splice(0, 6);
        const nextPlayerUid = idx === lastPlayerIdx ? playerIds[0] : playerIds[idx + 1];
        acc[playerId] = {
            ...players[playerId],
            gifs,
            nextPlayerUid
        };
        return acc;
    }, {});

    // create an active player record with the first player in the room
    const activePlayerId = playerIds[0];

    await Promise.all([
        gameByIdRef(game_id).update({ stage: STAGE_1, players: updated_players }),
        gifDeckByIdRef(game_id).set(gifIds),
        activePlayerByGameIdRef(game_id).set({
            nextPlayerUid: updated_players[activePlayerId].nextPlayerUid,
            uid: activePlayerId
        })
    ]);

};

export const shareHintAndGif = async (data) => {
    const { hint, chosenGif, gameId, gifIds, uid } = data;

    const chosenGifIdx = gifIds.indexOf(chosenGif);
    gifIds.splice(chosenGifIdx, 1);

    await Promise.all([
        gameByIdRef(gameId).update({ stage: STAGE_2 }),
        playerByIdRef(uid, gameId).update({ gifs: gifIds }),
        activePlayerByGameIdRef(gameId).update({ hint, chosenGif }),
        playerGifsByGameIdRef(gameId).update({
            [uid]: {
                chosenGif,
                isActive: true
            }
        })
    ]);
};

export const chooseGif = (gameId, chosenGif, uid, gifIds, playerLength) => {
    playerGifsByGameIdRef(gameId).transaction(data => {
        data = data ?? {};
        data[uid] = {
            chosenGif,
            isActive: false
        };
        return data;
    }, async (err, completed, snapshot) => {
        if (completed) {
            // Remove from players Gifs
            const chosenGifIdx = gifIds.indexOf(chosenGif);
            gifIds.splice(chosenGifIdx, 1);

            await playerByIdRef(uid, gameId).update({ gifs: gifIds });

            const numPlayers = snapshot.numChildren();
            // Once all players have chosen a gif (including active player), then advance to next stage
            if (numPlayers === playerLength) {
                await gameByIdRef(gameId).update({ stage: STAGE_3 });
            }
        }
    });
};

export const vote = (gameId, gifId, playerUid, player_gifs) => {
    votingByGameIdRef(gameId).transaction(data => {
        data = data ?? {};

        for (const uid in player_gifs) {
            if (player_gifs[uid].chosenGif === gifId) {
                data[playerUid] = {
                    chosenGif: gifId,
                    isOwnerActive: player_gifs[uid].isActive,
                    ownerUid: uid
                };
            }
        }

        return data;
    }, async (err, completed, snapshot) => {
        // if (completed) {
        //     const numPlayers = snapshot.numChildren();
        //     if (numPlayers + 1 === Object.keys(player_gifs).length) {
        //         await gameByIdRef(gameId).update({ stage: 4 });
        //     }
        // }
    });
};

export const calculateScores = async (gameId, points, players) => {
    let gameOver = false;

    for (let uid in points) {
        players[uid].points += points[uid];
        if (!gameOver && players[uid].points >= 30) {
            gameOver = true;
        }
    }

    const stage = gameOver ? STAGE_5 : STAGE_4;

    await gameByIdRef(gameId).update({ stage, players });
};

export const advanceNextRound = async (active_player, game_state) => {
    const gameId = game_state.game_id;

    // Create New Active Player object
    const updated_active_player = {
        uid: active_player.nextPlayerUid,
        nextPlayerUid: game_state.players[active_player.nextPlayerUid].nextPlayerUid
    };

    // Give all players one new GIF so they have a total of 6 GIFs each
    const gifDeckSnapshot = await gifDeckByIdRef(gameId).once('value');
    const gifIds = shuffle(gifDeckSnapshot.val());

    const updated_players = reduce(game_state.players, (acc, player, uid) => {
        const newGifId = gifIds.splice(0, 1);
        acc[uid] = {
            ...player,
            gifs: [...player.gifs, ...newGifId]
        };
        return acc;
    }, {});

    await Promise.all([
        activePlayerByGameIdRef(gameId).set(updated_active_player),
        gameByIdRef(gameId).update({ players: updated_players, stage: STAGE_1 }),
        gifDeckByIdRef(gameId).set(gifIds),
        playerGifsByGameIdRef(gameId).set(null),
        votingByGameIdRef(gameId).set(null)
    ]);
};
